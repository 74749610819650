/* src/pages/PrivacyPolicy.css */

.container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .privacy-policy h1 {
    margin-bottom: 20px;
    font-size: 2.5rem;
    text-align: left; /* Ensure the main heading is left-aligned */
  }
  
  .privacy-policy h2 {
    margin-top: 30px;
    font-size: 2rem;
    color: #333;
    text-align: left; /* Ensure h2 headings are left-aligned */
  }
  
  .privacy-policy h3 {
    margin-top: 20px;
    font-size: 1.5rem;
    color: #333;
    text-align: left; /* Ensure h3 headings are left-aligned */
  }
  
  .privacy-policy h4 {
    margin-top: 15px;
    font-size: 1.25rem;
    color: #333;
    text-align: left; /* Ensure h4 headings are left-aligned */
  }
  
  .privacy-policy p,
  .privacy-policy li {
    color: #555;
    line-height: 1.6;
    text-align: left; /* Ensure paragraphs and list items are left-aligned */
  }
  
  .privacy-policy ul {
    list-style-type: disc;
    margin-left: 20px;
    text-align: left; /* Ensure lists are left-aligned */
  }
  
  .privacy-policy a {
    color: #007bff;
    text-decoration: none;
  }
  
  .privacy-policy a:hover {
    text-decoration: underline;
  }
  