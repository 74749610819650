/* src/components/Footer.css */

/* Container styling */
.footer .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Heading styles */
  .footer-content h2,
  .footer-content h3,
  .footer-content h4 {
    color: #333;
    text-align: left; /* Ensure headings are left-aligned */
  }
  
  /* Paragraph and list styles */
  .footer-content p,
  .footer-content li {
    color: #555;
    line-height: 1.6;
    text-align: left; /* Ensure text is left-aligned */
  }
  
  /* List styling */
  .footer-content ul {
    list-style-type: disc;
    margin-left: 20px;
    text-align: left; /* Ensure lists are left-aligned */
  }
  
  /* Link styling */
  .footer-content a {
    color: #007bff;
    text-decoration: none;
  }
  
  .footer-content a:hover {
    text-decoration: underline;
  }
  
  /* Collapsible footer content for mobile */
  .footer-content.collapsed {
    display: none;
  }
  
  .footer-content.expanded {
    display: block;
  }
  
  /* Ensure footer content is always visible on large screens */
  @media (min-width: 992px) { /* Bootstrap's lg breakpoint */
    .footer-content.collapsed {
      display: block !important;
    }
  }
  