.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
/* src/App.css */

.header {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem; /* Equivalent to mb-4 */
}

.header-logo {
  width: 50px;
  height: auto;
  margin-right: 10px;
}

/* Responsive Adjustments (Optional) */
@media (max-width: 576px) {
  .header-logo {
    width: 40px;
    margin-right: 8px;
  }

  .header h1 {
    font-size: 1.5rem; /* Adjust font size for smaller screens */
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* Fixed Footer Styles */
.fixed-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  /* Optional: Add box-shadow for separation */
  box-shadow: 0 -2px 5px rgba(0,0,0,0.1);
  z-index: 1000; /* Ensure it stays on top */
}

/* In your App.css or a dedicated CSS file */

.top-names {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
}

.top-names h3 {
  margin-bottom: 20px;
}

.top-names ol {
  padding-left: 20px;
}

.top-names li {
  font-size: 18px;
  margin-bottom: 10px;
}
